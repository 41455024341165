import { QueryClient, useQuery } from '@tanstack/vue-query'
import QueryKeys from '@/vue-query/QueryKeys'
import { useParams } from '../utils/useParams'
import { useFirewallPolicyUiApi } from '../api/useFirewallPolicyUiApi'
import { UserProjectFirewallPolicyContextDto } from '~/api/contracts/models'

export function useUserProjectFirewallPolicyContextQuery() {
   const { projectId } = useParams()
   
   const client = useFirewallPolicyUiApi()
   
   const enabled = computed(() => !!projectId.value)

   return useQuery([QueryKeys.FirewallPolicyContext, projectId], () => client.getFirewallPolicyContext({ projectId: projectId.value }), { enabled: enabled, refetchOnWindowFocus: false, staleTime: Infinity})
}

export function useUserProjectFirewallPolicyContextQueryPrefetch(
   queryClient: QueryClient,
   projectId: Ref<string>
): Promise<void> {
   const client = useFirewallPolicyUiApi()
   return queryClient.prefetchQuery<UserProjectFirewallPolicyContextDto>(
      [QueryKeys.FirewallPolicyContext, projectId],
      () => client.getFirewallPolicyContext({ projectId: projectId.value })
   )
}