import { ContentType, HttpClient, RequestParams } from "./base/BaseHttpClient";
import {
  SetUserProjectFirewallPolicyContextRequest,
  SetUserProjectFirewallPolicyFiltersRequest,
  UserProjectFirewallPolicyContextDto,
  UserProjectFirewallPolicyFiltersDto,
} from "./contracts/models";

export class FirewallPolicyUiApi<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags FirewallPolicyUi
   * @name GetFirewallPolicyContext
   * @request GET:/api/firewall-policy-ui/context
   * @response `200` `UserProjectFirewallPolicyContextDto` Success
   */
  getFirewallPolicyContext = (
    query?: {
      projectId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http
      .request<UserProjectFirewallPolicyContextDto, any>({
        path: `/api/firewall-policy-ui/context`,
        method: "GET",
        credentials: "include",
        query: query,
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags FirewallPolicyUi
   * @name SetFirewallPolicyContext
   * @request POST:/api/firewall-policy-ui/context
   * @response `200` `void` Success
   */
  setFirewallPolicyContext = (data: SetUserProjectFirewallPolicyContextRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/firewall-policy-ui/context`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags FirewallPolicyUi
   * @name GetFirewallPolicyFilters
   * @request GET:/api/firewall-policy-ui/{firewallPolicyId}/filters
   * @response `200` `UserProjectFirewallPolicyFiltersDto` Success
   */
  getFirewallPolicyFilters = (firewallPolicyId: string, params: RequestParams = {}) =>
    this.http
      .request<UserProjectFirewallPolicyFiltersDto, any>({
        path: `/api/firewall-policy-ui/${firewallPolicyId}/filters`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags FirewallPolicyUi
   * @name SetFirewallPolicyFilters
   * @request POST:/api/firewall-policy-ui/{firewallPolicyId}/filters
   * @response `200` `void` Success
   */
  setFirewallPolicyFilters = (
    firewallPolicyId: string,
    data: SetUserProjectFirewallPolicyFiltersRequest,
    params: RequestParams = {},
  ) =>
    this.http
      .request<void, any>({
        path: `/api/firewall-policy-ui/${firewallPolicyId}/filters`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
}
